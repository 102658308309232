<template>
    
    <v-card class="container-modul">
        
        <!-- tab my profile personal info -->
        <v-card>
            <v-card-title><v-icon class="mr-2" color="blackIcon">mdi-account-details</v-icon>Personal Info</v-card-title>
            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 70%;">
                    </colgroup>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Employee Name :</td>
                            <td>{{ personal.name }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">IC Number :</td>
                            <td>{{ personal.ic }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Gender :</td>
                            <td>{{ personal.gender == 'M' ? 'Male' : 'Female' }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Address :</td>
                            <td>{{ personal.address }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Postal Code :</td>
                            <td>{{ personal.postalcode }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">City :</td>
                            <td>{{ personal.city }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">State :</td>
                            <td>{{ personal.statename }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Tel No (H/P) :</td>
                            <td>{{ personal.hp }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Tel No (Home) :</td>
                            <td>{{ personal.homeno }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Email 1 :</td>
                            <td>{{ personal.email1 }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Email 2 :</td>
                            <td>{{ personal.email2 }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Age :</td>
                            <td>{{ personal.age }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Date Of Birth :</td>
                            <td>{{ convertDateString(personal.datebirth) }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Place Of Birth :</td>
                            <td>{{ personal.placeofbirth }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Marital Status :</td>
                            <td>{{ personal.maritalstatus }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Driving License :</td>
                            <td>
                                <v-list dense>
                                    <v-list-item v-if="license.d == 'd'">
                                        <v-list-item-icon>
                                            <v-icon>mdi-car</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>D</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="license.da == 'da'">
                                        <v-list-item-icon>
                                            <v-icon>mdi-car</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>DA</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="license.b2 == 'b2'">
                                        <v-list-item-icon>
                                            <v-icon>mdi-motorbike</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>B2</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                <v-card-subtitle v-if="personal.maritalstatus != 'Single'">
                    <v-icon color="dark-grey" class="mr-1">mdi-human-male-female</v-icon> Spouse's Info
                </v-card-subtitle>

                <v-simple-table v-if="personal.maritalstatus != 'Single'">
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 70%;">
                    </colgroup>
                    <tbody>
                        <tr 
                            v-for="item, key in spouseProfile"
                            :key="item.spousename"
                        >

                            <td class="font-weight-bold">Spouse Name {{ key+1 }} :</td>
                            <td>{{ item.spousename }}</td>

                        </tr>
                    </tbody>
                </v-simple-table>

                <v-card-subtitle v-if="personal.maritalstatus != 'Single'">
                    <v-icon color="dark-grey" class="mr-1">mdi-human-male-female-child</v-icon> Child's Info
                </v-card-subtitle>

                <v-simple-table v-if="personal.maritalstatus != 'Single'">
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 70%;">
                    </colgroup>
                    <tbody>
                        <tr 
                            v-for="item, key in childProfile"
                            :key="item.childrenname"
                        >

                            <td class="font-weight-bold">Child Name {{ key+1 }} :</td>
                            <td>{{ item.childrenname }}</td>

                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>

        <!-- tab my profile employee info -->
        <v-card class="mt-5">
            <v-card-title><v-icon class="mr-2" color="blackIcon">mdi-account-tie</v-icon>Employee Info</v-card-title>
            <v-card-text>
                <v-simple-table>
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 70%;">
                    </colgroup>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Date Of Employement :</td>
                            <td>{{ convertDateString(employee.dateofemployment) }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Personal Grade :</td>
                            <td>{{ employee.personalgrade }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Position :</td>
                            <td>{{ employee.position }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">System Role :</td>
                            <td>{{ employee.systemrole }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Company :</td>
                            <td>{{ employee.company }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Staff Number :</td>
                            <td>{{ employee.staffnum }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Type Of Employement :</td>
                            <td>{{ employee.employtype }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Card Access Number :</td>
                            <td>{{ employee.cardnum }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Supervisor :</td>
                            <td>{{ employee.supervisorname }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Superior :</td>
                            <td>{{ employee.superiorname }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Total Annual Leave :
                                <v-tooltip max-width="450" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        mdi-information
                                        </v-icon>
                                    </template>
                                    <span>Total Annual Leave is the combination of Annual Leave Entitlements (based on years of service) and Carried Forward Leaves (from previous year - if any).</span>
                                </v-tooltip>
                            </td>
                            <td>{{ employee.totalannualleave }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Total Annual Leave Applied :</td>
                            <td>{{ employee.annualleaveused }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Total Carry Forward Leave : 
                                <v-tooltip max-width="450" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        mdi-information
                                        </v-icon>
                                    </template>
                                    <span>An employee may carry forward annual leave of up to 7 working days for the following year. Any unused carry forward leave shall be forfeited on 1 May.</span>
                                </v-tooltip>
                            </td>
                            <td>{{ employee.countcarryforward }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Total Medical Leave :</td>
                            <td>{{ employee.totalmedicalleave }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Total Medical Leave Applied :</td>
                            <td>{{ employee.medicalleaveused }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Annual Leave Adjustment :</td>
                            <td>{{ employee.annualleaveadjustment }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">End Of Employement :</td>
                            <td>{{ employee.endofemployment }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>

        <!-- tab my profile hr info -->
        <v-card class="mt-5">
            <v-card-title><v-icon class="mr-2" color="blackIcon">mdi-briefcase-account-outline</v-icon>HR Info</v-card-title>
            <v-card-text>
                <v-card-subtitle class="pa-0 pl-1" >
                    <v-icon color="dark-grey" class="mr-1">mdi-bank-outline</v-icon> Financial Info - For Office Use Only
                </v-card-subtitle>
                
                <v-simple-table>
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 70%;">
                    </colgroup>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">EPF Number :</td>
                            <td>{{ finance.epfno }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">SOCSO Number :</td>
                            <td>{{ finance.socsono }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">LHDN Number :</td>
                            <td>{{ finance.lhdnno }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Bank Account Number :</td>
                            <td>{{ finance.accbankno }}</td>
                        </tr>

                        <tr>
                            <td class="font-weight-bold">Bank :</td>
                            <td>{{ finance.namebank }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <v-card-subtitle class="pa-0 pl-1 pt-5" >
                    <v-icon color="dark-grey" class="mr-1">mdi-school-outline</v-icon> Educational Info
                </v-card-subtitle>

                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Qualification</th>
                            <th>Course/Major</th>
                            <th>Institute/University</th>
                            <th>Graduation Year</th>
                            <th>Certificate</th>
                        </tr>
                    </thead>
                    <tbody v-if="qualifyProfile.length > 0">
                        <tr 
                            v-for="item in qualifyProfile"
                            :key="item.qualification"
                        >

                            <td>{{ item.qualification }}</td>
                            <td>{{ item.course }}</td>
                            <td>{{ item.institute }}</td>
                            <td>{{ item.graduation }}</td>
                            <td>
                                <v-icon
                                small
                                class="mr-2"
                                @click="viewCert(item.cert)"
                                v-if="item.cert != null && item.cert != ''"
                                >
                                mdi-file-document-outline
                                </v-icon>
                            </td>

                        </tr>
                    </tbody>
                    <tbody v-else>
                            <tr>

                                <td colspan="5">No Educational Info</td>

                            </tr>
                        </tbody>
                </v-simple-table>

                <v-card-subtitle class="pa-0 pl-1 pt-5" >
                    <v-icon color="dark-grey" class="mr-1">mdi-script-text-outline</v-icon> Professional Certification
                </v-card-subtitle>

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Organisation</th>
                                <th>Members No</th>
                                <th>Certificate</th>
                            </tr>
                        </thead>
                        <tbody v-if="certificateProfile.length > 0">
                            <tr 
                            v-for="item in certificateProfile"
                            :key="item.memberno"
                            >

                                <td>{{ item.organisation }}</td>
                                <td>{{ item.memberno }}</td>
                                <td>
                                    <v-icon
                                    small
                                    class="mr-2"
                                    @click="viewCert(item.cert)"
                                    v-if="item.cert != null && item.cert != ''"
                                    >
                                    mdi-file-document-outline
                                    </v-icon>
                                </td>

                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>

                                <td colspan="3">No Professional Certification Info</td>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card-text>
        </v-card>

        <!-- tab my profile emergency contact-->
        <v-card class="mt-5">
            <v-card-title><v-icon class="mr-2" color="blackIcon">mdi-card-account-phone-outline</v-icon>Emergency Contact</v-card-title>
            <v-card-text>
                <v-card-subtitle class="pa-0 pl-1" >
                    <v-icon color="dark-grey" class="mr-1">mdi-phone-outline</v-icon> Emergency Contact 1
                </v-card-subtitle>
                    
                <v-simple-table>
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 70%;">
                    </colgroup>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Name :</td>
                            <td>{{ emergency[0].ecname }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Address :</td>
                            <td>{{ emergency[0].address }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Postal Code :</td>
                            <td>{{ emergency[0].postalcode }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">City :</td>
                            <td>{{ emergency[0].city }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">State :</td>
                            <td>{{ emergency[0].state }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Tel No (H/P) :</td>
                            <td>{{ emergency[0].phone }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Tel No (Home) :</td>
                            <td>{{ emergency[0].homephone }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Tel No (Office) :</td>
                            <td>{{ emergency[0].officephone }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Relationship :</td>
                            <td>{{ emergency[0].relationship }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <v-card-subtitle class="pa-0 pl-1 pt-5" >
                    <v-icon color="dark-grey" class="mr-1">mdi-phone-outline</v-icon> Emergency Contact 2
                </v-card-subtitle>

                <v-simple-table>
                    <colgroup>
                        <col span="1" style="width: 30%;">
                        <col span="1" style="width: 70%;">
                    </colgroup>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Name :</td>
                            <td>{{ emergency[1].ecname }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Address :</td>
                            <td>{{ emergency[1].address }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Postal Code :</td>
                            <td>{{ emergency[1].postalcode }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">City :</td>
                            <td>{{ emergency[1].city }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">State :</td>
                            <td>{{ emergency[1].state }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Tel No (H/P) :</td>
                            <td>{{ emergency[1].phone }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Tel No (Home) :</td>
                            <td>{{ emergency[1].homephone }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Tel No (Office) :</td>
                            <td>{{ emergency[1].officephone }}</td>
                        </tr>
                        
                        <tr>
                            <td class="font-weight-bold">Relationship :</td>
                            <td>{{ emergency[1].relationship }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    data: () => ({
        personal:[],
        license:[],
        employee:[],
        finance:{
            idemp: '',
            epfno: '',
            socsono: '',
            lhdnno: '',
            accbankno: '',
            namebank: ''
        },
        emergency:[
            {
                idemp: '',
                idstate:'',
                ecname: '',
                address: '',
                postalcode: '',
                city: '',
                homephone:'',
                officephone: '',
                phone: '',
                relationship: ''
            },
            {
                idemp: '',
                idstate:'',
                ecname: '',
                address: '',
                postalcode: '',
                city: '',
                homephone:'',
                officephone: '',
                phone: '',
                relationship: ''
            }
        ],
        state:[],

        //spouseprofile
        headersSpouseProfile: [
            {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'spousename',
            },
        ],
        spouseProfile: [],

        //childprofile
        headersChildProfile: [
            {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'childrenname',
            },
        ],
        childProfile: [],

        //qualifyprofile
        headersQualifyProfile: [
            {
            text: 'Qualification',
            align: 'start',
            sortable: false,
            value: 'qualification',
            },
        { text: 'Course/Major', value: 'course' },
        { text: 'Institute/University', value: 'institute' },
        { text: 'Graduation Year', value: 'graduation' },
        ],
        qualifyProfile: [],

        //certificateprofile
        headersCertificateProfile: [
        {
            text: 'Organisation',
            align: 'start',
            sortable: false,
            value: 'organisation',
        },
        { text: 'Members Number', value: 'memberno' },
        ],
        certificateProfile: [],
        
    }),
    // computed: {

    // },
    methods: {
        getMyProfile(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=1&id='+this.$store.getters.user.idEmp,{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                
                this.spouseProfile = response.data.spouse;
                this.childProfile = response.data.children;
                // this.license = response.data.drivinglicense;
                this.license.b2= (response.data.drivinglicense != null) ? response.data.drivinglicense.b2 == null ? null : 'b2' : null;
                this.license.d = (response.data.drivinglicense != null) ? response.data.drivinglicense.d == null ? null : 'd' : null;
                this.license.da = (response.data.drivinglicense != null) ? response.data.drivinglicense.da == null ? null : 'da' : null;
                this.employee = response.data.employeeinfo;
                this.qualifyProfile = response.data.educationinfo;
                this.certificateProfile = response.data.certifications;
                
                if(response.data.personalinfo.datebirth != null){
                    response.data.personalinfo.datebirth = response.data.personalinfo.datebirth.split('T')[0];
                }

                this.personal = response.data.personalinfo;

                (response.data.financeinfo != null ? this.finance = response.data.financeinfo : '');
                
                if (response.data.emergencycontacts.length != 0){
                    this.emergency = response.data.emergencycontacts;

                    for(let i = 0; i < this.emergency.length; i ++){
                        for( let l in this.state){
                            if(this.emergency[i].idstate == this.state[l].idstate){
                                this.emergency[i]['state'] = this.state[l].name;
                            }
                        }
                    }
                }
                

            })
            .catch(error => {
                console.log(error);
            })
        },

        getDropdown(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token,
                    }
                })
                .then((response) => {
                    this.state = response.data.states;
                })
                .catch(error => {
                    console.log(error);
                })

        },
        viewCert(link){
            window.open(
                link,
                '_blank'
            );
        },

    },
    mounted() {
        this.getDropdown();
        this.getMyProfile();
    },

    created (){
        bus.$on('changeIt', (data) => {

            if (data == "loadProfile") {
                this.getMyProfile();
            }

        })
    }
}
</script>

<style lang="scss" scoped>
@import '~scss/main';

.overflow-visible {
    white-space: normal !important;
}

.profile-title {
    max-width: 300px;
}

</style>
